<template>
  <div class="matchmaker">
    <!-- <van-nav-bar
      title="选择红娘"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    /> -->
    <div class="list" v-for="(item, index) in list" :key="index">
      <div class="first">
        <div class="photo">
          <img :src="item.headimgurl" alt="" />
        </div>
        <div class="info">
          <div class="name">{{ item.nickname }}</div>
          <div class="descripe van-ellipsis">{{ item.description }}</div>
        </div>
      </div>
      <div class="second">
        <div
          class="btn"
          @click="changeMatchmaker(item)"
          v-if="hasMatchmaker == 1"
        >
          更换
        </div>
        <div class="btn" @click="addMatchmaker(item)" v-else>添加</div>
      </div>
    </div>
  </div>
</template>

<script>
import { matchmaker_list, bind_matchmaker } from "@/api/my";
export default {
  name: "matchmaker",
  components: {},
  data() {
    return {
      list: [],
      total: 0,
      hasMatchmaker: null
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        const res = await matchmaker_list();
        this.list = res.data.data.list;
        this.total = res.data.data.count;
        this.hasMatchmaker = res.data.data.hasMatchmaker;
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    },
    /* onClickLeft() {
      this.$router.back();
    }, */
    async changeMatchmaker(item) {
      try {
        const res = await bind_matchmaker({ m_id: item.id });
        if (res.data.code == 200) {
          this.$router.push("/wode");
          this.getList();
        }
        console.log(res);
      } catch (err) {
        console.log(err);
      }
    },
    async addMatchmaker(item) {
      try {
        const res = await bind_matchmaker({ m_id: item.id });
        if (res.data.code == 200) {
          this.getList();
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.matchmaker {
  /deep/.van-nav-bar .van-icon {
    color: #333333;
  }
  .list {
    // height: 194px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 47px 28px;
    border-bottom: 1px solid #eee;
    .first {
      display: flex;
      .photo {
        width: 101px;
        height: 101px;
        margin-right: 19px;
        img {
          width: 101px;
          height: 101px;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        padding: 12px 0;
        .name {
          margin-bottom: 16px;
          font-weight: 600;
          color: #000000;
          font-size: 33px;
        }
        .descripe {
          width: 394px;
          font-size: 25px;
          color: #999999;
        }
      }
    }
    .second {
      .btn {
        width: 146px;
        height: 62px;
        background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
        border-radius: 31px;
        text-align: center;
        line-height: 62px;
        font-size: 32px;
        color: #ffffff;
      }
    }
  }
}
</style>
